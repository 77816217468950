import React from "react"
import '../components/fontawesome'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Links = () => (
    <div className ="links">
        <ul>
            <li><FontAwesomeIcon icon={['fab', 'facebook-square']} /></li>
            <li><FontAwesomeIcon icon={['fab', 'instagram-square']} /></li>
            <li><FontAwesomeIcon icon={['fab', 'linkedin']} /></li>
            <li><FontAwesomeIcon icon={['fab', 'twitter']} /></li>
            <li><FontAwesomeIcon icon={['fab', 'youtube']} /></li>
        </ul>
    </div>
)

export default Links