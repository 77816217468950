import React from "react"

import '../styles/styles.scss'

//import components
import Header from '../components/header.js'
import Banner from '../components/banner.js'
import Links from '../components/links.js'

const IndexPage = () => (
  <div>
    <Links />
    <Header />
    <Banner /> 
  </div>
)

export default IndexPage
