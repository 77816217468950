import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header>
    <div className="container">
      <div className="inner-header">
        <div className="navigation">
          <nav>
            <a href="https://linktr.ee/_adriel">Work</a>
            <a href="mailto:adriel@espaillat.dev">Contact</a>
          </nav>
        </div>
        <div className="logo">
          <Link to="/">ESPAILLAT</Link>
        </div>
      </div>
    </div>
  </header> 
)

export default Header
